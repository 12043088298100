import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  isMobileView(): boolean {
    return window.innerWidth < 769;
  }

  calculateLuminance(hexColor: string): number {
    const rgb = parseInt(hexColor.substring(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >>  8) & 0xff;
    const b = (rgb >>  0) & 0xff;
  
    // Apply gamma correction
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma;
  }
  
}
